import React, {Component} from "react"
import Layout from "../gatsby-theme-blog/components/layout"
import Footer from "../custom-components/footer"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Error from '../assets/error404.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

export default()=>{
    return(
 

  <Layout>

    <div className="p-4 container text-center w-full">
      <div  >
        <Error className="h-32 w-32 inline-block" />
      </div>
    
    <h2 className="py-3 text-2xl block">Sorry, this page doesn't exists</h2>

    <h2 className="py-3 text-2xl ">you can go back to the <AniLink  swipe direction="left" to="/" >
      <FontAwesomeIcon icon={faHome} className="text-green-600 fa-1x ml-2"/> page</AniLink>
      </h2>
    <h2 className="py-3 text-2xl ">or stay here and reflect on life </h2>

    </div>
  </Layout>
 
 
)  }

   